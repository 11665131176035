<template>
    <div>
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
                <el-breadcrumb-item>自提空桶入库单</el-breadcrumb-item>
                <el-breadcrumb-item>新增自提空桶入库</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="padding: 5px;">
                <i class="el-icon-close icon" @click="closeClick"></i>
            </div>
        </div>
        <div
            style="height: 80px;border: 1px solid #e5e5ee;background-color: #F5FAFE;display: flex;justify-content: space-between;margin: 10px 5px;">
            <div style="padding: 10px 20px;">
                <p style="font-size: 30px;">空桶入库</p>
                <p style="font-size: 12px;">日期：<span>{{ date }}</span></p>
            </div>
            <div>
                <el-button type="primary" icon="iconfont icon-baocun" @click="handleSave">保存</el-button>
            </div>
        </div>
        <div
            style="border: 1px solid #e5e5ee;background-color: #F5FAFE;display: flex;justify-content: center;align-items: center;padding: 5px;margin: 10px 5px;">
            <el-col :span="6">
                <div>
                    <span style="font-size: 14px;">选择水站：</span>
                    <el-select v-model="waterOpt" clearable style="width: 50%;">
                        <el-option v-for="item in waterOpts" :key="item.id" :label="item.value" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="3"></el-col>
            <el-col :span="8">
                <div style="display: flex;align-items: center;font-size: 14px;">
                    <span style="width: 40%;">订单备注：</span>
                    <el-input v-model="remark"></el-input>
                </div>
            </el-col>
        </div>
        <div style="border: 1px solid #e5e5ee;background-color: #F5FAFE;margin: 20px 5px;">
            <table class="table1">
                <tr>
                    <th>序号</th>
                    <th>品名</th>
                    <th>入库(含烂桶)</th>
                    <th>兑换桶</th>
                    <th>外品牌1:1</th>
                    <th>外品牌2:1</th>
                    <th>外品牌3:1</th>
                    <th>外桶补差价1:1</th>
                    <th>外桶补差价金额</th>
                    <th>总品牌入库</th>
                    <th>操作</th>
                </tr>
                <tr style="height: 20px;" v-for="(table, index) in tables1" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ table.name }}</td>
                    <td> <el-input type="text" v-model="table.remark" /> </td>
                    <td> <el-input type="text" v-model="table.conversion" /> </td>

                    <td> <el-input type="text" v-model="table.foreign1" /> </td>
                    <td> <el-input type="text" v-model="table.foreign2" /> </td>
                    <td> <el-input type="text" v-model="table.foreign3" /> </td>

                    <!-- <td> <el-input type="text" v-model="table.foreign1" /> </td> -->
                    <td> <el-input type="text" v-model="table.fillPostPrice" /> </td>
                    <td> <el-input type="text" v-model="table.totalRemark" /> </td>
                    <td>
                        <div>
                            <i class="el-icon-delete" @click="del('tables1', index)"></i>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="11" style="padding: 10px 40px;text-align: left;">
                        <div>
                            <el-select v-model="barrel" clearable style="width: 10%;margin-right: 10px;">
                                <el-option v-for="item in barrelOpt" :key="item.name" :label="item.name" :value="item.name">
                                </el-option>
                            </el-select>
                            <el-link icon="el-icon-plus" @click="add_barrel">添加空桶</el-link>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="8">合计:{{ tables1.length > 0 ? tables1.map(el => parseInt(el.fillPostPrice)).reduce(function
                        (x, y) {
                        return x + y
                    }) : "0" }}</td>
                </tr>
            </table>
        </div>
        <div style="background-color: #F5FAFE;margin: 20px 5px;">
            <span>漏桶：</span>
            <table class="table1">
                <tr>
                    <th>序号</th>
                    <th>商品名</th>
                    <th>桶品牌</th>
                    <th>单价</th>
                    <th>数量</th>
                    <th>金额</th>
                    <th>操作</th>
                </tr>
                <tr style="height: 20px;" v-for="(table, index) in tables2" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ table.name }}</td>
                    <td>{{ table.brand }}</td>
                    <td> <el-input type="text" v-model="table.unitPrice" /> </td>
                    <td> <el-input type="text" v-model="table.number" /> </td>
                    <td>{{ table.number * parseInt(table.unitPrice) }}</td>
                    <td>
                        <div>
                            <i class="el-icon-delete" @click="del('tables2', index)"></i>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="7" style="padding: 10px 0;text-align: left;">
                        <div>
                            <el-select v-model="e_barrel" clearable style="width: 10%;margin-right: 10px;">
                                <el-option v-for="item in barrelOpt" :key="item.name" :label="item.name" :value="item.name"
                                    placeholder="请输入商品名称或货品">
                                </el-option>
                            </el-select>
                            <el-link icon="el-icon-plus" @click="add_e_barrel">添加漏桶</el-link>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">合计:{{ tables2.length > 0 ? tables2.map(el => el.number *
                        parseInt(el.unitPrice)).reduce((x, y) => x
                            + y) : "0" }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </table>
        </div>

    </div>
</template>
<script>

import { getDate } from '../../../../../until/utilse';
export default {

    data() {
        return {
            date: getDate(new Date()),
            commercial_id: localStorage.getItem("commercial_id") || sessionStorage.getItem("commercial_id"),
            waterOpts: [],
            tables1: [],
            barrelOpt: [],
            tables2: [],

            operator: localStorage.getItem("operator") || sessionStorage.getItem("operator"),
            waterOpt: '',
            remark: '',
            barrel: '',
            e_barrel: "",
        }
    },
    mounted() {

    },
    async created() {
        let res = (await this.$http.post("/Client/commercial_owner/lst")).data.data;
        this.waterOpts = res.map(el => {
            return {
                id: el.id,
                value: el.enterprise_name
            }
        });
        res = (await this.$http.post("/inventory/empty_barrel/lst", {
            commercial_id: this.commercial_id
        })).data.data;
        console.log(res);
        this.barrelOpt = res

    },
    computed: {

    },
    methods: {
        del(obj, index) {
            this[obj].splice(index, 1)
        },
        add_e_barrel() {

            // <td>{{ table.id }}</td>
            // <td>{{ table.name }}</td>
            // <td>{{ table.brand }}</td>
            // <td>{{ table.unitPrice }}</td>
            // <td>{{ table.number }}</td>
            // <td>{{ table.price }}</td>

            if (this.e_barrel) {
                this.tables2.push({
                    id: this.tables2.length + 1,
                    name: this.e_barrel,
                    brand: this.e_barrel,
                    unitPrice: 0,
                    number: 1,
                    price: 0,
                })
            } else {
                this.$message("请选择后添加")
            }
        },
        add_barrel() {
            if (this.barrel) {
                this.tables1.push({
                    id: this.tables1.length + 1,
                    name: this.barrel,
                    remark: 1,
                    conversion: 1,
                    foreign1: "",
                    foreign2: "",
                    foreign3: "",
                    fillPostPrice: 0,
                    totalRemark: 0,
                })
            } else {
                this.$message("请选择后添加")
            }

        },
        async handleSave() {
            if (!this.waterOpt) {
                this.$message("请选择水站")
                return false
            }
            let query = {
                commercial_id: this.commercial_id,
                notes: this.remark,
                site_name: this.waterOpt,
                operator: this.operator,
                barrel_list: this.tables1,
                e_barrel_list: this.tables2
            };
            // console.log(query);
            let { data } = await this.$http.post("/Station/Barrel_Entry/add", query);
            if (data.status == "success") {
                this.$message.success(data.msg);
                this.closeClick()
            } else {
                this.$message.success("添加失败")
            }
        },
        closeClick() {
            this.$emit('close', true)
        },
    },
}
</script>
<style scoped lang="less">
.icon {
    cursor: pointer;
}

.icon:hover {
    color: red;
}

.breadcrumb {
    height: 40px;
    line-height: 40px;
    box-sizing: content-box;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 20px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table1 {
    border-collapse: collapse;
    width: 100%;

    tr {
        th {
            border: 1px solid #e5e5e5;
            padding: 6px 20px;
        }

        td {
            border: 1px solid #e5e5e5;
            text-align: center;
            padding: 6px 20px;
        }
    }
}
</style>

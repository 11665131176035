import { render, staticRenderFns } from "./emptyBarrel_add.vue?vue&type=template&id=a4d6e434&scoped=true&"
import script from "./emptyBarrel_add.vue?vue&type=script&lang=js&"
export * from "./emptyBarrel_add.vue?vue&type=script&lang=js&"
import style0 from "./emptyBarrel_add.vue?vue&type=style&index=0&id=a4d6e434&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4d6e434",
  null
  
)

export default component.exports